import Cookies from "js-cookie";
import Api from "./api";

const AdminService = {
	async resumo() {
		try {
			const response = await Api.get(`app/admin/home`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	},

	async dashboard() {
		try {
			const response = await Api.get(`app/admin/dashboard`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	},

	async listaUsuarios() {
		try {
			const response = await Api.get(`app/admin/usuarios`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	},

	async diagnose(id: Number) {
		try {
			const response = await Api.get(`app/admin/diagnose/${id}`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	},

	async listGradesRecurso(idUsuario: number, idRecurso: number) {
		try {
			const response = await Api.get(
				`app/admin/nota/recurso/${idUsuario}/${idRecurso}`,
			);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	},

	async changePassword(idUsuario: number, data: { passwordChange: string; confirmPasswordChange: string; }) {
		try {
			const response = await Api.post(`app/admin/alterarSenha`, {
				idUsuario: idUsuario,
				newPassword: data.passwordChange,
				confirmNewPassword: data.confirmPasswordChange
			});

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	},

	async listAllOccupation() {
		try {
			const response = await Api.post(`app/admin/ocupacoes`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	}
};

export default AdminService;
