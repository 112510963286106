import "./ModalChangePassword.scss";

import {
	Button,
	Container,
	Form,
	Modal,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { schema } from "./Validate/Validate";
import { useAuth } from "../../providers/AuthProvider";
import { toast } from "react-toastify";

interface ChangePasswordInputs {
	passwordChange: string;
	confirmPasswordChange: string;
}

interface ModalNewsProps {
	show: boolean;
}

const ModalChangePassword = ({ show }: ModalNewsProps) => {
	const [modal, setModal] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const {changePassword} = useAuth();

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm<ChangePasswordInputs>({
		mode: "onChange",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		if (show) {
			reset();
			setIsSubmitting(false);
		}
		setModal(show);
	}, [show]);

	const handleChangePassword = async (data: {
		passwordChange: string;
		confirmPasswordChange: string;
	}) => {
		setIsSubmitting(true);
		await changePassword(data);
		setIsSubmitting(false);
	};

	return (
		<Modal
			centered
			scrollable
			size="lg"
			isOpen={modal}
			className="modal-change-password"
			data-testid="ModalChangePassword"
		>
			<Container>
				<div className="image brand">
					<img src="assets/img/brand.png" alt="UELF" />
				</div>

				<div className="message">
					<h1 className="title p">Altere a senha!</h1>
					<h2 className="title s">
						Use 8 ou mais caracteres com uma combinação de letras maiúculas, letras
						minúsculas e números sem caracteres especiais.
					</h2>
				</div>

				<Form onSubmit={handleSubmit(handleChangePassword)}>
					<div className="fieldset mt-4">
						<label htmlFor="password-change">Senha</label>
						<input
							type="password"
							id="password-change"
							{...register("passwordChange", { required: true })}
							maxLength={25}
						/>
								{errors.passwordChange?.type === "required" && (
									<p className="error">A senha não foi prenchida</p>
								)}
								{errors.passwordChange?.type === "min" && (
									<p className="error">A senha precisa ter no minimo 8 caraceteres</p>
								)}
								{errors.passwordChange?.type === "max" && (
									<p className="error">A senha precisa ter no maximo 25 caraceteres</p>
								)}
						<p className="error">{errors.passwordChange?.type != "required" && (errors.passwordChange?.message)}</p>
					</div>
					<div className="fieldset">
						<label htmlFor="confirm-password-change">Repita a senha</label>
						<input
							type="password"
							id="confirm-password-change"
							{...register("confirmPasswordChange", { required: true })}
							maxLength={25}
						/>

						<p className="error">{errors.confirmPasswordChange?.message}</p>
					</div>
					<Button type="submit" disabled={isSubmitting} className="btndefault w-100 mt-5">
						Continuar
					</Button>
					<div className="text">
						<p>Problemas com seu acesso?</p>
						<p>Procure o RH</p>
					</div>
				</Form>
			</Container>
		</Modal>
	);
};

export default ModalChangePassword;
