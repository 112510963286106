import "./CardResourceAdmin.scss";

import React, { useEffect, useState, FC } from "react";
import { Card, CardBody, CardTitle, Col } from "reactstrap";
import { useDashboard } from "../../providers/DashboardProvider";
import { Doughnut } from "react-chartjs-2";
import FormatNote from "../../utils/FormatNote";

const chartOptions = {
	options: {
		responsive: true,
		maintainAspectRatio: true,
		aspectRatio: 2,
		cutout: 105,
		legend: {
			display: false,
		},
		animation: {
			animateScale: true,
			animateRotate: true,
		},
	},
};

const CardResourceAdmin = ({ recurso }: any) => {
	const { renderBgIconHex, renderIcon } = useDashboard();

	return (
		<div className="CardResourceAdmin">
			<Card>
				<div className="items row">
					<Col lg={6}	xl={2} xs={12}>
						<div className="d-flex align-items-center">
							<div className="image">{renderIcon(0, recurso?.titulo)}</div>
							<div>
								<h1
									className="title"
									style={{ color: renderBgIconHex(recurso?.titulo) }}
								>
									{recurso?.titulo}
								</h1>
								<h2 className="subtitle">Sub-recursos</h2>
							</div>
						</div>
					</Col>
					<Col lg={6}	xl={3} xs={12}>
						<div className="graphic p-0">
							<div style={{ position: "relative", height: "auto", width: "25vw" }}>
								<Doughnut
									data={{
										datasets: [
											{
												data: [
													parseFloat(recurso?.mediaGeral),
													10 - parseFloat(recurso?.mediaGeral),
												],
												backgroundColor: [renderBgIconHex(recurso?.titulo), "#8B8A79"],
												hoverBackgroundColor: [renderBgIconHex(recurso?.titulo), "#8B8A79"],
												borderWidth: 0,
											},
										],
									}}
									options={chartOptions.options}
									width={250}
									height={250}
								/>
								<p className="m-0">{FormatNote(recurso?.mediaGeral)}</p>
							</div>
						</div>
					</Col>
					<Col lg={12} xl={7} xs={12}>
						<div className="subresources d-flex justify-content-center">
							{recurso.subRecursos &&
								recurso.subRecursos.map((sub: any) => {
									return (
										<div className="item" key={sub.idSubRecurso}>
											<h3 className="item-title">{sub?.descricao}</h3>
											<div className="graphic m-0">
												<div style={{ position: "relative", height: "auto", width: "8vw" }}>
													<Doughnut
														data={{
															datasets: [
																{
																	data: [parseFloat(sub?.media), 10 - parseFloat(sub?.media)],
																	backgroundColor: [renderBgIconHex(recurso?.titulo), "#8B8A79"],
																	hoverBackgroundColor: [
																		renderBgIconHex(recurso?.titulo),
																		"#8B8A79",
																	],
																	borderWidth: 0,
																},
															],
														}}
														options={{
															cutout: 60,
														}}
														width={160}
														height={160}
													/>
													<p className="m-0">{FormatNote(sub?.media)}</p>
												</div>
											</div>
										</div>
									);
								})}
						</div>
					</Col>
				</div>
			</Card>
		</div>
	);
};

// @ts-ignore
export default CardResourceAdmin;
