import * as yup from "yup";

const Validation = yup.object().shape({
  email: yup
    .string()
    .email("O campo precisa ser do tipo e-mail")
    .required("O campo e-mail é obrigatório"),
  password: yup.string().required("O campo senha é obrigatório"),
});

export default Validation;
