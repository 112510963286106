import { faMoon, faSun } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDashboard } from "../../providers/DashboardProvider";
import "./ThemeToggle.scss";

export default function ThemeToggle() {
	const { setTheme } = useDashboard();
	const location = useLocation();

	const [activeTheme, setActiveTheme] = useState(
		document.body.getAttribute("color-scheme") ||
			(location.pathname.startsWith("/admin") ? "light" : "dark"),
	);
	const inactiveTheme = activeTheme === "light" ? "dark" : "light";

	const toggleTheme = () => {
		setActiveTheme(inactiveTheme);
	};

	useEffect(() => {
		const savedTheme = location.pathname.startsWith("/admin")
			? localStorage.getItem("theme:admin")
			: localStorage.getItem("theme");
		savedTheme && setActiveTheme(savedTheme);
	}, []);

	useEffect(() => {
		document.body.setAttribute("color-scheme", activeTheme);
		if (location.pathname.startsWith("/admin")) {
			localStorage.setItem("theme:admin", activeTheme);
		} else {
			localStorage.setItem("theme", activeTheme);
		}
		setTheme(activeTheme);
	}, [activeTheme]);

	return (
		<button
			type="button"
			className={`themeToggle ${activeTheme}`}
			onClick={toggleTheme}
			aria-label={`Alterar para o modo ${inactiveTheme}`}
			title={`Alterar para o modo ${inactiveTheme}`}
		>
			<div className="iconContainer">
				<FontAwesomeIcon icon={faSun} className={"light"} />
				<FontAwesomeIcon icon={faMoon} className={"dark"} />
			</div>
		</button>
	);
}
