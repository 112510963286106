import api from "./api";

class AuthService {
	async login(data: { email: string; password: string; toggle: boolean }) {
		try {
			const response = await api.post(`app/auth/login`, {
				email: data.email,
				password: data.password,
				toggle: data.toggle,
			});

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	}

	async signUp(data: any, imagem: File) {
		try {
			let formdata = new FormData();

			formdata.append("nomeRazao", data.nomeRazao);
			formdata.append("cpfCnpj", data.cpfCnpj);
			formdata.append("email", data.email);
			formdata.append("senha", data.senha);
			formdata.append("repeatPass", data.repeatPass);
			formdata.append("dataNascimentoAbertura", data.dataNascimentoAbertura);
			formdata.append("celular", data.celular);
			if (!!data.enderecos) {
				formdata.append("enderecos", JSON.stringify(data.enderecos));
			}
			formdata.append("ocupacao", data.ocupacao);
			formdata.append("tipoPessoa", data.tipoPessoa);
			formdata.append("sendWhats", data.sendWhats);
			formdata.append("imagem", imagem);

			const response = await api.post(`app/pessoa/create`, formdata);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	}

	async getDados() {
		try {
			const response = await api.get(`app/pessoa/getDadosUsuario`);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Houve um erro ao carregar os dados, tente novamente mais tarde.",
			];
		}
	}

	async updateProfile(data: any, imagem: File) {
		try {
			let formdata = new FormData();

			formdata.append("nomeRazao", data.nomeRazao);
			formdata.append("cpfCnpj", data.cpfCnpj);
			formdata.append("email", data.email);
			formdata.append("dataNascimentoAbertura", data.dataNascimentoAbertura);
			formdata.append("celular", data.celular);
			formdata.append("enderecos", JSON.stringify(data.enderecos));
			formdata.append("ocupacao", data.ocupacao);
			formdata.append("tipoPessoa", data.tipoPessoa);
			formdata.append("imagem", imagem);

			const response = await api.post(`app/pessoa/update`, formdata);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	}

	async updatePartialProfile(data: any) {
		try {
			let formdata = new FormData();

			if (data.nomeRazao)
				formdata.append("nomeRazao", data?.nomeRazao || null);
			if (data.cpfCnpj)
				formdata.append("cpfCnpj", data?.cpfCnpj || null);
			if (data.email)
				formdata.append("email", data?.email || null);
			if (data.dataNascimento)
				formdata.append("dataNascimentoAbertura", data?.dataNascimento || null);
			if (data.celular)
				formdata.append("celular", data?.celular || null);
			if (data.enderecos)
				formdata.append("enderecos", JSON.stringify(data?.enderecos || null));
			if (data.ocupacao)
				formdata.append("ocupacao", data?.ocupacao || null);
			if (data.tipoPessoa)
				formdata.append("tipoPessoa", data?.tipoPessoa || null);

			const response = await api.post(`app/pessoa/updatePartial`, formdata);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	}

	async SendRecoveryEmail(data: { login: string }) {
		try {
			const response = await api.post(`app/usuario/recuperar/senha`, data);

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Não foi possível realizar essa ação por favor tente mais tarde.",
			];
		}
	}

	async logout() {
		try {
			const response = await api.post(`app/auth/logout`, {});
			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	}

	async changePassword(data: { passwordChange: string; confirmPasswordChange: string; }) {
		try {
			const response = await api.post(`app/auth/alterarSenha`, {
				newPassword: data.passwordChange,
				confirmNewPassword: data.confirmPasswordChange
			});

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
	}
}

export default AuthService;
