import * as yup from "yup";

export const schema = yup.object().shape({
	passwordChange: yup
		.string()
		.required()
		.matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
			"Senha não atinge os requisitos mínimos",
		)
		.min(8)
		.max(25),
	confirmPasswordChange: yup
		.string()
		.oneOf([yup.ref("passwordChange"), null], "As senhas não conferem"),
});
