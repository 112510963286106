import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Button } from "reactstrap";
import Footer from "../../components/Footer/Footer";
import ModalAccountRecovery from "../../components/ModalAccountRecovery/ModalAccountRecovery";
import { useAuth } from "../../providers/AuthProvider";
import GoogleTagManager, { EventEnum } from "../../services/GoogleTagManager";
import SignUp from "../SignUp/SignUp";
import "./Login.scss";
import { schema } from "./Validate/Validate";

interface LoginInputs {
	email: string;
	password: string;
	toggle: boolean;
}

const usePathname = (): string => {
	const location = useLocation();
	return location.pathname;
};

const Login = () => {
	const { login, setError, activeTab, setActiveTab, firstLogin } = useAuth();
	const [isSubmitting, setIsSubmitting] = useState(false);

	type FormValues = {
		email: string;
		password: string;
		toggle: boolean;
	};

	let defaultValues = {
		email: "",
		password: "",
		toggle: false,
	} as FormValues;

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm<LoginInputs>({
		mode: "onChange",
		defaultValues,
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		const lembre = localStorage.getItem("lembreme");

		if (lembre) {
			const lembremeJson = JSON.parse(lembre);

			if (lembremeJson.toggle) {
				setValue("email", lembremeJson?.email!);
				setValue("password", lembremeJson?.password!);
				setValue("toggle", lembremeJson?.toggle!);
				window.onload = () => {
					document.getElementById("email")?.focus();
					document.getElementById("pass")?.focus();
				};
			}
		}
	}, []);

	const navigate = useNavigate();
	const pathname = usePathname();

	const [showModalRecovery, setShowModalRecovery] = useState<boolean>(false);

	useEffect(() => {
		GoogleTagManager.event(EventEnum.Marketing, { action: "cadastro" });
		pathname !== "/login" && navigate("/login");
	}, [pathname, navigate]);

	const handleLogin = async (data: {
		email: string;
		password: string;
		toggle: boolean;
	}) => {
		setIsSubmitting(true);
		setError("");
		const redirect = await login(data);
		if(!firstLogin) {
			redirect && navigate(`/${redirect}`);
			setIsSubmitting(false);
		}
	};

	return (
		<div className="login">
			<Toaster />

			<div className="col form-content form-default">
				<div className="image brand">
					<img src="assets/img/brand.png" alt="UELF" />
				</div>

				<div className="welcome-message">
					<h1 className="title p futura-bold">Bem-vindo!</h1>
					<h2 className="title s">Preencha as informações para continuar</h2>
				</div>

				<div className="tabs">
					<div className="links-group">
						<button
							className={`tablink ${activeTab === "login" ? "is-active" : ""}`}
							onClick={() => setActiveTab("login")}
							id="defaultOpen"
						>
							Login
						</button>
						<button
							className={`tablink ${activeTab === "register" ? "is-active" : ""}`}
							onClick={() => setActiveTab("register")}
						>
							Cadastre-se
						</button>
					</div>

					<div className={`tabcontent ${activeTab === "login" ? "is-active" : ""}`}>
						<form key={0} onSubmit={handleSubmit(handleLogin)}>
							<div className="fieldset">
								<label htmlFor="email">E-mail</label>
								<input
									type="email"
									id="email"
									maxLength={150}
									className={`${!errors.email || "invalid"}`}
									{...register("email", { required: true })}
								/>
								{errors.email?.type === "required" && (
									<p className="error">O email não foi prenchido</p>
								)}
								{errors.email?.type === "email" && (
									<p className="error">O email informado não é valido</p>
								)}
								{errors.email?.type === "max" && (
									<p className="error">O email precisa ter no maximo 150 caracteres</p>
								)}
							</div>
							<div className="fieldset">
								<label htmlFor="password">Senha</label>
								<input
									type="password"
									id="password"
									maxLength={25}
									className={`${!errors.password || "invalid"}`}
									{...register("password", { required: true })}
								/>
								{errors.password?.type === "required" && (
									<p className="error">A senha não foi prenchida</p>
								)}
								{errors.password?.type === "min" && (
									<p className="error">A senha precisa ter no minimo 8 caraceteres</p>
								)}
								{errors.password?.type === "max" && (
									<p className="error">A senha precisa ter no maximo 25 caraceteres</p>
								)}
							</div>
							<div className="fieldset toggle">
								<label className="toggle" htmlFor="toggle">
									<input
										type="checkbox"
										id="toggle"
										className="toggle-input"
										{...register("toggle", { required: true })}
									/>
									<span className="toggle-display">
										<i className="bi bi-check toggle-icon checked"></i>
										<i className="bi bi-x toggle-icon not-checked"></i>
									</span>
									<span>Lembrar-me</span>
								</label>
							</div>

							<Button
								type="submit"
								id="login"
								className="btndefault w-100"
								disabled={isSubmitting}
							>
								Login
							</Button>

							<div className="fieldset align-content-center">
								<button
									type="button"
									className="link forgot-pass"
									onClick={() => setShowModalRecovery(true)}
								>
									Esqueceu a senha ? Recuperar a senha ?
								</button>
							</div>
						</form>
					</div>

					<div
						className={`tabcontent ${activeTab === "register" ? "is-active" : ""}`}
					>
						<SignUp />
					</div>
				</div>
				<Footer />
			</div>
			<div
				className="col visual-content d-none d-md-flex"
				style={{
					backgroundImage: `url('assets/img/login.png')`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
				}}
			>
				<div className="wrapper">
					<div className="item">
						<span>
							Você sabe o que é <br />{" "}
							<b className="futura-bold">essencial para você?</b>
						</span>
					</div>
					<div className="item">
						<span>
							Descubra como focar e{" "}
							<b className="futura-bold">
								potencializar <br /> sua vida na prática!
							</b>
						</span>
					</div>
				</div>
			</div>

			<ModalAccountRecovery
				show={showModalRecovery}
				onClose={setShowModalRecovery}
			/>

			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</div>
	);
};

export default Login;
