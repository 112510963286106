import "./CardGradesResources.scss";

import { Button, Card, Col, Row } from "reactstrap";
import React, { FC, useEffect, useState, useRef } from "react";

import CardScore from "../../CardScore/CardScore";
import CardScore2 from "../../CardScore2/CardScore2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Plyr from "plyr";
import VideoModal from "../../VideoModal/VideoModal";
import { faCheck, faPrint } from "@fortawesome/free-solid-svg-icons";
import { useDashboard } from "../../../providers/DashboardProvider";
import { useReactToPrint } from "react-to-print";
import { Link } from "react-router-dom";
import ModalTrail from "../../ModalTrail/ModalTrail";

interface CardGradesResourcesProps {
	data: any[];
	page: string;
	cardscore?: JSX.Element;
	titleTutorial?: string;
	videoTutorial?: string;
	informacaoPiorSubRecurso?: any;
	showVideo?: boolean;
}

const CardGradesResources: FC<CardGradesResourcesProps> = ({
	data,
	page,
	cardscore,
	titleTutorial,
	videoTutorial,
	informacaoPiorSubRecurso,
	showVideo = true,
}) => {
	const player = new Plyr("#player");
	const { renderIcon, renderBgIcon, theme, renderBgIconHex } = useDashboard();
	const [style, setStyle] = useState({ "--list-color": "var(--c1)" });
	const [showModalTrail, setShowModalTrail] = useState<boolean>(false);
	const [sourceModalTrail, setSourceModalTrail] = useState<string>("");

	useEffect(() => {
		if (informacaoPiorSubRecurso?.length) {
			setStyle({
				"--list-color": informacaoPiorSubRecurso.piorSubRecurso.corFundo,
			});
		}
	}, [informacaoPiorSubRecurso]);

	useEffect(() => {
		if (sourceModalTrail != "") setShowModalTrail(true);
	}, [sourceModalTrail]);

	const componentRefPrint = useRef(null);
	const handlePrint = useReactToPrint({
		content: () => componentRefPrint.current,
	});

	return (
		<div
			className="CardGradesResources"
			data-testid="CardGradesResources"
			style={style as React.CSSProperties}
		>
			{data?.length > 0 && (
				<>
					<div className="d-flex justify-content-end">
						<Button className="action-print" onClick={handlePrint}>
							<FontAwesomeIcon icon={faPrint} /> Salvar
						</Button>
					</div>
					<div ref={componentRefPrint}>
						<div className="square">
							<div className="content">
								{data.map((item: any, key: number) => {
									return (
										<>
											{page === "diagnose" ? (
												<CardScore key={key} item={item} />
											) : (
												<CardScore2 key={key} item={item} />
											)}
										</>
									);
								})}
								{cardscore}
							</div>
						</div>

						{informacaoPiorSubRecurso && (
							<div className="piorrecurso wrapper">
								<Card
									className={`card-inverse bg-uelf ${informacaoPiorSubRecurso.piorSubRecurso.recurso}`}
									style={{
										border: `7px solid ${renderBgIconHex(
											informacaoPiorSubRecurso.piorSubRecurso.recurso,
										)}`,
										// background: `url('${process.env.PUBLIC_URL}/assets/img/bg-uelf-${theme}.png') var(--c3)`,
									}}
								>
									<div className="introducao">
										<div className="image">
											{renderIcon(0, informacaoPiorSubRecurso.piorSubRecurso.recurso)}
										</div>
										<div className="grouping">
											<div className="title main">
												<span>{informacaoPiorSubRecurso.piorSubRecurso.descricao}</span>
											</div>
											<div
												className="title"
												style={{ color: informacaoPiorSubRecurso.piorSubRecurso.corFundo }}
											>
												<span>{informacaoPiorSubRecurso.piorSubRecurso.recurso}</span>
											</div>
											<div className="text">
												{informacaoPiorSubRecurso?.introducao.map(
													(item: any, key: number) => {
														return <p key={key}>{item?.descricao}</p>;
													},
												)}
											</div>
										</div>
									</div>
									{/* <div className="avaliacao">
										<span className="title">O que minha avaliação me diz</span>
										<div className="text">
											{informacaoPiorSubRecurso?.avaliacao.map(
												(item: any, key: number) => {
													return <p key={key}>{item?.descricao}</p>;
												},
											)}
										</div>
									</div> */}
									{/* <div className="atividades">
										<span className="title">Atividades desta experiência</span>
										<ul className="special-list-type">
											{informacaoPiorSubRecurso?.atividades.map(
												(item: any, key: number) => {
													return <li key={key}>{item?.descricao}</li>;
												},
											)}
										</ul>
									</div> */}
									<div className="aprendizado">
										{/* <div className="wrapper">
											<span className="title">O que você poderá aprender</span>
											<ul>
												{informacaoPiorSubRecurso?.aprendizado.map(
													(item: any, key: number) => {
														return (
															<li key={key}>
																<FontAwesomeIcon
																	icon={faCheck}
																	style={{
																		background:
																			informacaoPiorSubRecurso?.piorSubRecurso?.corFundo,
																	}}
																/>
																{item?.descricao}
															</li>
														);
													},
												)}
											</ul>
										</div> */}
										<div className="wrapper">
											<div
												className="image-title"
												style={{
													background: `url('${
														process.env.PUBLIC_URL
													}/assets/img/dicas/bg-dicas-${renderBgIcon(
														informacaoPiorSubRecurso.piorSubRecurso.recurso,
													)}.svg') no-repeat center`,
												}}
											>
												O que você escolheu evitar
											</div>
											<div className="text">
												{informacaoPiorSubRecurso?.listaEvitarFazer.map(
													(item: any, key: number) => {
														if (item?.idEtapa === 3)
															return (
																<p key={key}>
																	<img
																		src={`/assets/img/icons/common/redellipse.svg`}
																		className="mr-3"
																	/>
																	{item?.resposta}
																</p>
															);
													},
												)}
											</div>
										</div>
										<div className="wrapper">
											<div
												className="image-title"
												style={{
													background: `url('${
														process.env.PUBLIC_URL
													}/assets/img/dicas/bg-dicas-${renderBgIcon(
														informacaoPiorSubRecurso.piorSubRecurso.recurso,
													)}.svg') no-repeat center`,
												}}
											>
												O que você escolheu fazer
											</div>
											<div className="text">
												{informacaoPiorSubRecurso?.listaEvitarFazer.map(
													(item: any, key: number) => {
														if (item?.idEtapa === 4)
															return (
																<p key={key}>
																	<img
																		src={`/assets/img/icons/common/greenellipse.svg`}
																		className="mr-3"
																	/>
																	{item?.resposta}
																</p>
															);
													},
												)}
											</div>
										</div>
									</div>
								</Card>
								{informacaoPiorSubRecurso?.trilhas != undefined && (
									<Card className="card-learning">
										<Row>
											<Col className="col-12 col-md-6">
												<div className="title main">
													<span>Primeiro Passo:</span>
												</div>
												<p className="text">
													Espaço reservado para você dar o primeiro passo rumo a sua melhor
													versão. Clique nas imagens ao lado para começar.
												</p>

												<div className="list mt-5">
													{informacaoPiorSubRecurso?.aprendizado.map((v: any, k: number) => {
														return (
															<p
																style={{
																	background: `url('${
																		process.env.PUBLIC_URL
																	}/assets/img/icons/checks/${renderBgIcon(
																		informacaoPiorSubRecurso.piorSubRecurso.recurso,
																	)}.svg') no-repeat left`,
																	paddingLeft: "80px",
																}}
																key={k}
															>
																{v.descricao}
															</p>
														);
													})}
												</div>
											</Col>
											<Col className="col-12 col-md-6">
												<div className="learning">
													<div className="wrapper">
														<div
															className="image-title"
															style={{
																background: `url('${
																	process.env.PUBLIC_URL
																}/assets/img/dicas/bg-dicas-${renderBgIcon(
																	informacaoPiorSubRecurso.piorSubRecurso.recurso,
																)}.svg') no-repeat center`,
																padding: "2rem",
															}}
														>
															Trilha da Gestão da{" "}
															{informacaoPiorSubRecurso.piorSubRecurso.recurso}
														</div>
														<div className="trails wrap-3">
															{informacaoPiorSubRecurso?.trilhas.map((v: any, k: number) => {
																return (
																	<div key={k} className="trail">
																		<button
																			style={{
																				background: renderBgIconHex(
																					informacaoPiorSubRecurso.piorSubRecurso.recurso,
																				),
																			}}
																			onClick={() => {
																				setSourceModalTrail(v?.url);
																			}}
																		>
																			{v?.aula}
																		</button>
																	</div>
																);
															})}
														</div>
													</div>
												</div>
											</Col>
										</Row>
									</Card>
								)}
							</div>
						)}
					</div>

					{/* <div className="tutorial" style={{display:(showVideo ? "block" : "none")}}>
						<Card>
							<div className="title">{titleTutorial}</div>
							<Button
								className="btn-video-modal"
								onClick={() => {
									setShowModalVideo(true);
								}}
							>
								<div className="image">
									<img src="assets/video-icon.png" alt="Video Icon" />
								</div>
								<span>ASSISTA AO VÍDEO</span>
							</Button>
							<VideoModal
								show={showModalVideo}
								onClose={setShowModalVideo}
								// @ts-ignore
								url={videoTutorial}
							/>
							<div className="video-container">
								<div className="player">
									<video id="player" playsInline controls>
										<source src={videoTutorial} type="video/mp4" />
									</video>
								</div>
							</div>
						</Card>
					</div> */}
				</>
			)}
			<ModalTrail
				show={showModalTrail}
				onClose={setShowModalTrail}
				source={sourceModalTrail}
			/>
		</div>
	);
};

export default CardGradesResources;
