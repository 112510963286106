import "./TableListUsersAdmin.scss";
import { FC, useState, useEffect } from "react";
import Status from "../../enums/Status";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import ModalChangePasswordAdmin from "../ModalChangePasswordAdmin/ModalChangePasswordAdmin";
import { ToastContainer } from "react-toastify";
import { Button } from "reactstrap";
import moment from "moment";

type TableListUsersAdminProps = {
	users: UserDetail[];
	filter?: string;
	selectFilterDtConclusao?: string;
	selectFilterSetor?: string;
};

type UserDetail = {
	id: number;
	nomeRazaoSocial: string;
	nomeFantasia: string;
	cpfCnpj: string;
	email: string;
	dataNascimentoAbertura: string;
	ocupacao: string;
	tipoPessoa: string;
	telefone: string;
	celular: string;
	status: number;
	dataCriacao: string;
	horaCriacao: string;
	sendWhats: number;
	usuarioJornadaInfo: {
		jornadasConcluidas: any;
	};
};

const TableListUsersAdmin: FC<TableListUsersAdminProps> = ({
	users,
	filter = "",
	selectFilterDtConclusao,
	selectFilterSetor
}: TableListUsersAdminProps) => {
	const [idUsuarioChangePassword, setIdUsuarioChangePassword] =
		useState<number>(0);
	const [showChangePassword, setShowChangePassword] = useState<boolean>(false);

	useEffect(() => {
		if (idUsuarioChangePassword) {
			setShowChangePassword(true);
		}
	}, [idUsuarioChangePassword]);
	
	const columns = [
		{
			name: "Usuário",
			selector: (row: UserDetail) => row.nomeRazaoSocial,
		},
		{
			name: "E-mail",
			selector: (row: UserDetail) => row.email,
		},
		{
			name: "Setor",
			selector: (row: UserDetail) => row.ocupacao,
		},
		{
			name: "Conclusão Valuation",
			selector: (row: UserDetail) => {
				return row?.usuarioJornadaInfo?.jornadasConcluidas.length
					? row?.usuarioJornadaInfo?.jornadasConcluidas[0]["dataConclusao"]
					: "Não finalizado";
			},
			style: {
				fontWeight: "bold",
			},
		},
		{
			name: "Diagnóstico",
			cell: (row: UserDetail) => (
				<Link
					style={{ color: "#99DA35", fontWeight: "bold" }}
					to={`/admin/diagnose/${row.id}`}
				>
					Visualizar
				</Link>
			),
		},
		{
			name: "Senha",
			selector: (row: UserDetail) => Status[row.status],
			cell: (row: UserDetail) => (
				<a
					onClick={() => {
						setIdUsuarioChangePassword(row.id);
					}}
				>
					Alterar
				</a>
			),
			conditionalCellStyles: [
				{
					when: (row: UserDetail) => row.status == 1,
					style: {
						color: "#99DA35",
						fontWeight: "bold",
					},
				},
			],
		},
		{
			name: "Status",
			selector: (row: UserDetail) => Status[row.status],
			conditionalCellStyles: [
				{
					when: (row: UserDetail) => row.status == 1,
					style: {
						color: "#99DA35",
						fontWeight: "bold",
					},
				},
			],
		},
	];

	const filteredItems = users.filter(
		(item) => {
			let filtered = JSON.stringify(item).toLowerCase().indexOf(filter.toLowerCase()) !== -1;

			if(selectFilterSetor != "" && item.ocupacao != selectFilterSetor) {
				filtered = false;
			}

			if(selectFilterDtConclusao != "") {
				if(item?.usuarioJornadaInfo?.jornadasConcluidas.length){
					if(item?.usuarioJornadaInfo?.jornadasConcluidas[0]["dataConclusao"] != moment(selectFilterDtConclusao).format("DD/MM/YYYY")) {
						filtered = false; 
					} 
				}else { 
					filtered = false; 
				}
			} 

			return filtered;
		}
	);

	return (
		<>
			<div className="TableListUsersAdmin">
				<DataTable
					responsive
					highlightOnHover
					pagination
					columns={columns}
					data={filteredItems}
				/>
			</div>

			<ModalChangePasswordAdmin
				idUsuario={idUsuarioChangePassword}
				show={showChangePassword}
				onClose={setShowChangePassword}
			/>

			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</>
	);
};

export default TableListUsersAdmin;
