import "./Performance.scss";

import { Button, Card } from "reactstrap";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CardGrades from "../../components/JourneyStatistics/CardGrades/CardGrades";
import CardGradesResources from "../../components/JourneyStatistics/CardGradesResources/CardGradesResources";
import ChartPerformance from "../../components/ChartPerformance/ChartPerformance";
import DiagnoseService from "../../services/DiagnoseService";
import Loading from "../../components/Loading/Loading";
import ModalGradesSub from "../../components/ModalGradesSub/ModalGradesSub";
import PainelMasterPage from "../../components/PainelMasterPage/PainelMasterPage";
import { ToastContainer } from "react-toastify";
import { toastSettings } from "../../models/toastConfig";
import { useActiveJourney } from "../../providers/ActiveJourneyProvider";

export interface IData {
	grafico: any[];
	informacaoPiorSubRecurso: any;
	notasRecursos: any[];
	pontuacao: string;
	fraseNotaVida: string;
	status: string;
}

const Performance = () => {
	const [data, setData] = useState<IData>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const { activeJourney } = useActiveJourney();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (location.pathname !== "/login") {
			setIsLoading(true);
			handleList().then();
		}
	}, [activeJourney]);

	const handleList = async () => {
		try {
			const [_Response, _Error] = await new DiagnoseService().listProvisorio();

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				setIsLoading(false);
				return;
			}

			setData(_Response.resultSet);

			setIsLoading(false);
		} catch (err) {
			console.warn(err);
		}
	};

	const render = (data: IData) => {
		return (
			<div className="diagnose">
				<div className="row">
					<div className="col p-0">
						{data?.notasRecursos && data?.notasRecursos.length > 0 ? (
							<ChartPerformance items={data?.grafico} />
						) : (
							<Card className="card-inverse">
								<div className="items-wrapper jcenter">
									<p className="text-center h4 text-white my-5">
										{!activeJourney ? "Não há" : ""} Jornada em Andamento
									</p>
								</div>
							</Card>
						)}
					</div>
				</div>

				<div className="row">
					<div
						className={`col col-12 p-0 resource-notes ${
							data?.notasRecursos && data?.notasRecursos.length > 0 ? "h-100" : ""
						}`}
					>
						<CardGradesResources
							data={
								data?.notasRecursos && data?.notasRecursos.length > 0
									? data.notasRecursos
									: []
							}
							page={"performance"}
							cardscore={
								<div className="col p-0">
									<CardGrades
										pontuacao={data?.pontuacao}
										fraseNotaVida={data?.fraseNotaVida}
										status={data?.status}
									/>
								</div>
							}
							titleTutorial="Entenda os caminhos que você pode seguir a partir de agora"
							informacaoPiorSubRecurso={data?.informacaoPiorSubRecurso}
							videoTutorial="https://www.youtube.com/watch?v=ydPaX7Uh-ks"
						/>
					</div>
				</div>
			</div>
		);
	};

	return (
		<PainelMasterPage
			title={"Seu Resultado"}
			subtitle={"Clique nos recursos para ver o detalhamento das notas"}
			subtitleclass="text-white"
			icon={<></>}
			onClick={() => {}}
		>
			{!isLoading ? <>{data && render(data)}</> : <Loading margin="my-6" />}

			{/* <div className="row">
				<div className="col col-12 p-0">
					<div className="navigate">
						<Button
							onClick={() => {
								navigate("/journey-map");
							}}
						>
							<span>Continuar</span>
						</Button>
					</div>
				</div>
			</div> */}

			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			<ModalGradesSub />
		</PainelMasterPage>
	);
};

export default Performance;
