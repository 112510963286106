import React, { useEffect, useState } from "react";
import { Admin } from "../models/Admin";
import Cookies from "js-cookie";
import AuthAdminService from "../services/AuthAdminService";
import { toastSettings } from "../models/toastConfig";

export const AuthAdminContext = React.createContext<Admin | any>({});

export const AuthAdminProvider = (props: any) => {
	const [admin, setAdmin] = useState<Admin | null>(null);
	const [error, setError] = useState<string>("");

	useEffect(() => {
		const disposer = () => {
			const userStorage = localStorage.getItem("@uelf:adm");
			setAdmin(!!userStorage ? JSON.parse(userStorage) : null);
		};
		disposer();
	}, []);

	useEffect(() => {
		if (admin) {
			localStorage.setItem("@uelf:adm", JSON.stringify(admin));
		}
	}, [admin]);

	const login = async (data: { email: string; password: string }) => {
		const [_Response, _Error] = await AuthAdminService.login(data);

		if (!!_Error || !_Response?.status) {
			toastSettings(_Error || _Response?.message, "bottom-center", "error");
			return;
		}
		setAdmin({ ...admin, ..._Response?.resultSet?.usuario });

		Cookies.set("access_token_admin", _Response?.resultSet?.access_token_admin, {
			path: "/",
			expires: 1,
		});

		return true;
	};

	const logout = async () => {
		const logout = AuthAdminService.logout();

		return logout;
	};

	return (
		<AuthAdminContext.Provider
			value={{
				admin,
				setAdmin,
				login,
				error,
				setError,
				logout,
			}}
		>
			{props.children}
		</AuthAdminContext.Provider>
	);
};

export const useAdminAuth = () => React.useContext(AuthAdminContext);
