import "./ModalChangePasswordAdmin.scss";

import { Button, Container, Form, Modal, ModalHeader } from "reactstrap";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { schema } from "./Validate/Validate";
import { useAuth } from "../../providers/AuthProvider";
import AdminService from "../../services/AdminService";
import { toastSettings } from "../../models/toastConfig";
import Loading from "../Loading/Loading";

interface ChangePasswordInputs {
	passwordChange: string;
	confirmPasswordChange: string;
}

interface ModalNewsProps {
	idUsuario: number;
	show: boolean;
	onClose?: any;
}

const ModalChangePasswordAdmin = ({ idUsuario, show, onClose }: ModalNewsProps) => {
	const [modal, setModal] = useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [generatedPassword, setGeneratedPassword] = useState<string>("");
	const toggle = () => setModal(!modal);
	const handleOnClose = () => onClose(false);

	const {
		register,
		handleSubmit,
		setValue,
		reset,
		formState: { errors },
	} = useForm<ChangePasswordInputs>({
		mode: "onChange",
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		if (show) {
			reset();
			setIsSubmitting(false);
			setModal(show);
		}
	}, [show]);

	const handleChangePassword = async (data: {
		passwordChange: string;
		confirmPasswordChange: string;
	}) => {
		setIsSubmitting(true);

		const [_Response, _Error] = await AdminService.changePassword(idUsuario, data);

		setIsSubmitting(false);

		if (!!_Error || !_Response?.status) {
			toastSettings(_Error || _Response?.message, "bottom-center", "error");
		} else if (_Response?.status) {
			toastSettings("Senha alterada com sucesso!", "bottom-center", "success");
		}

		toggle();
	};

	const generatePassword = () => {
		const part1 = randomString(4).toString();
		const part2 = randomString(4).toString().toUpperCase();
		const part3 = randomInt(4).toString();

		setGeneratedPassword(part1 + part2 + part3);
	};

	function randomString(len: Number) {
		var p = "abcdefghijklmnopqrstuvwxyz";
		return [...Array(len)].reduce((a) => a + p[~~(Math.random() * p.length)], "");
	}

	function randomInt(len: Number) {
		var p = "0123456789";
		return [...Array(len)].reduce((a) => a + p[~~(Math.random() * p.length)], "");
	}

	return (
		<Modal
			centered
			scrollable
			size="lg"
			isOpen={modal}
			onClosed={handleOnClose}
			className="modal-change-password-admin"
			data-testid="ModalChangePasswordAdmin"
		>
			<ModalHeader toggle={toggle}></ModalHeader>
			<Container>
				<div className="message">
					<h1 className="title p">Altere a senha!</h1>
					<h2 className="title s">
						Use 8 ou mais caracteres com uma combinação de letras maiúsculas, letras
						minúsculas e números sem caracteres especiais.
					</h2>
				</div>

				<Form onSubmit={handleSubmit(handleChangePassword)}>
					<div className="fieldset mt-4">
						<label htmlFor="password-change">Senha</label>
						<input
							type="password"
							id="password-change"
							{...register("passwordChange", { required: true })}
							maxLength={25}
							autoComplete="false"
						/>
						{errors.passwordChange?.type === "required" && (
							<p className="error">A senha não foi prenchida</p>
						)}
						{errors.passwordChange?.type === "min" && (
							<p className="error">A senha precisa ter no minimo 8 caraceteres</p>
						)}
						{errors.passwordChange?.type === "max" && (
							<p className="error">A senha precisa ter no maximo 25 caraceteres</p>
						)}
						<p className="error">
							{errors.passwordChange?.type != "required" &&
								errors.passwordChange?.message}
						</p>
					</div>
					<div className="fieldset">
						<label htmlFor="confirm-password-change">Repita a senha</label>
						<input
							type="password"
							id="confirm-password-change"
							{...register("confirmPasswordChange", { required: true })}
							maxLength={25}
							autoComplete="false"
						/>

						<p className="error">{errors.confirmPasswordChange?.message}</p>
					</div>
					<div className="fieldset">
						<Button
							type="button"
							className="btndefault w-100 mt-5"
							onClick={generatePassword}
						>
							Gerar uma senha
						</Button>
						<input type="text" id="password-generator" value={generatedPassword} />
					</div>
					{isSubmitting && <Loading/>}
					<Button
						type="submit"
						disabled={isSubmitting}
						className="btndefault w-100 mt-5"
					>
						Continuar
					</Button>
				</Form>
			</Container>
		</Modal>
	);
};

export default ModalChangePasswordAdmin;
