import React, { useEffect, useState } from "react";

import { useActiveJourney } from "./ActiveJourneyProvider";
import FormatLog from "../utils/FormatLog";
import { useLocation } from "react-router-dom";

export const DashboardContext = React.createContext<any>({});

export const DashboardProvider = (props: any) => {
	const [chartJornadas, setChartJornadas] = useState<any>([]);
	const [showModalDetails, setShowModalDetails] = useState<boolean>(false);
	const [idRecurso, setIdRecurso] = useState<number>(0);
	const [theme, setTheme] = useState<string>("");
	const location = useLocation();

	const { completedJourneys } = useActiveJourney();

	const handleGraficoJornadas = (data: any) => {
		let labels: any = [];

		data.forEach((item: any) => {
			labels.push(item.dataConclusao);
		});

		let qtdPontuacoes = data.map((item: any) => {
			if(item.notaVida === undefined) return null;
			return item.notaVida.toFixed(2);
		});

		setChartJornadas({
			type: "line",
			data: {
				labels: labels,
				datasets: [
					{
						label: "Jornadas",
						data: qtdPontuacoes,
						fill: false,
						borderColor: "#807F6D",
						tension: 0,
						backgroundColor: "#adcd56",
						radius: 8,
						borderCapStyle: "rounded",
						borderDash: [9],
					},
				],
			},
			options: {
				responsive: true,
				plugins: {
					tooltip: {
						callbacks: {
							label: function (item: any, data: any) {
								return "Nota da vida: " + item.raw.replace(".", ",");
							},
						},
					},
					title: {
						display: true,
					},
					autocolors: false,
					annotation: {
						annotations: {
							box1: {
								drawTime: "beforeDraw",
								type: "box",
								xMin: 0,
								xMax: 10,
								yMin: 0,
								yMax: 2,
								backgroundColor: "rgba(200, 63, 60, 0.5)",
								label: {
									enabled: true,
									content: ["Insciente"],
									font: {
										size: 16,
									},
									textAlign: "left",
									xAdjust: 10,
									yAdjust: 50,
									position: "start",
									color: "#ffffff8f",
								},
							},
							box2: {
								drawTime: "beforeDraw",
								type: "box",
								xMin: 0,
								xMax: 10,
								yMin: 2,
								yMax: 4,
								backgroundColor: "rgba(100, 56, 132, 0.5)",
								label: {
									enabled: true,
									content: ["Frágil"],
									font: {
										size: 16,
									},
									textAlign: "left",
									xAdjust: 10,
									yAdjust: 50,
									position: "start",
									color: "#ffffff8f",
								},
							},
							box3: {
								drawTime: "beforeDraw",
								type: "box",
								xMin: 0,
								xMax: 10,
								yMin: 4,
								yMax: 6,
								backgroundColor: "rgba(112, 180, 217, 0.5)",
								label: {
									enabled: true,
									content: ["Robusto"],
									font: {
										size: 16,
									},
									textAlign: "left",
									xAdjust: 10,
									yAdjust: 50,
									position: "start",
									color: "#ffffff8f",
								},
							},
							box4: {
								drawTime: "beforeDraw",
								type: "box",
								xMin: 0,
								xMax: 10,
								yMin: 6,
								yMax: 8,
								backgroundColor: "rgba(220, 150, 86, 0.5)",
								label: {
									enabled: true,
									content: ["Antifrágil"],
									font: {
										size: 16,
									},
									textAlign: "left",
									xAdjust: 10,
									yAdjust: 50,
									position: "start",
									color: "#ffffff8f",
								},
							},
							box5: {
								drawTime: "beforeDraw",
								type: "box",
								xMin: 0,
								xMax: 10,
								yMin: 8,
								yMax: 10,
								backgroundColor: "rgba(146, 183, 85, 0.5)",
								label: {
									enabled: true,
									content: ["UELF"],
									font: {
										size: 16,
									},
									textAlign: "left",
									xAdjust: 10,
									yAdjust: 50,
									position: "start",
									color: "#ffffff8f",
								},
							},
						},
					},
					legend: {
						display: false,
					},
				},
				scales: {
					x: {
						display: true,
						ticks: {
							color: "#FFF",
							padding: 10
						},
					},
					y: {
						display: true,
						min: 0,
						max: 10,
						grid: {
							display: false,
							color: "#0000",
							borderDash: [10, 10],
							borderDashOffset: 10,
						},
						ticks: {
							color: "#FFF",
							padding: 10,
						},
					},
				},
			},
		});
	};

	const renderIcon = (id: number, name: string) => {
		if (id === 1 || name === "Dinheiro") {
			return (
				<img src="/assets/img/icons/resources/icon_money.svg" alt="Dinheiro" />
			);
		} else if (id === 2 || name === "Saúde") {
			return <img src="/assets/img/icons/resources/icon_health.svg" alt="Saúde" />;
		} else if (id === 3 || name === "Conhecimento") {
			return (
				<img
					src="/assets/img/icons/resources/icon_knowledge.svg"
					alt="Conhecimento"
				/>
			);
		} else if (id === 4 || name === "Tempo") {
			return <img src="/assets/img/icons/resources/icon_time.svg" alt="Tempo" />;
		} else if (id === 5 || name === "Emoções") {
			return (
				<img src="/assets/img/icons/resources/icon_emotions.svg" alt="Emoções" />
			);
		}
	};

	const renderBgIcon = (item: string) => {
		if (item === "Dinheiro") {
			return "money";
		} else if (item === "Saúde") {
			return "health";
		} else if (item === "Conhecimento") {
			return "knowledge";
		} else if (item === "Tempo") {
			return "time";
		} else if (item === "Emoções") {
			return "emotions";
		}
	};

	const renderBgIconHex = (item: string) => {
		if (item === "Dinheiro") {
			return "hsla(82, 72%, 39%, 1)";
		} else if (item === "Saúde") {
			return "hsl(199, 55%, 59%)";
		} else if (item === "Conhecimento") {
			return "hsl(26, 67%, 59%)";
		} else if (item === "Tempo") {
			return "hsl(273, 38%, 35%)";
		} else if (item === "Emoções") {
			return "hsl(356, 57%, 50%)";
		}
	};

	useEffect(() => {
		if (completedJourneys) {
			handleGraficoJornadas(completedJourneys);
		}
	}, [completedJourneys]);

	useEffect(() => {
		const disposer = () => {
			const themeAt = location.pathname.startsWith("/admin") ? localStorage.getItem("theme:admin") : localStorage.getItem("theme");
			setTheme(themeAt || "");
		};
		disposer();
	}, []);

	return (
		<DashboardContext.Provider
			value={{
				chartJornadas,
				renderIcon,
				renderBgIcon,
				renderBgIconHex,
				showModalDetails,
				setShowModalDetails,
				idRecurso,
				setIdRecurso,
				theme,
				setTheme,
			}}
		>
			{props.children}
		</DashboardContext.Provider>
	);
};

export const useDashboard = () => React.useContext(DashboardContext);
