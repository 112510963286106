import "./PerformanceAdmin.scss";

import { Card } from "reactstrap";
import { useEffect, useState } from "react";
import CardGrades from "../../components/JourneyStatistics/CardGrades/CardGrades";
import CardGradesResources from "../../components/JourneyStatistics/CardGradesResources/CardGradesResources";
import ChartPerformance from "../../components/ChartPerformance/ChartPerformance";
import Loading from "../../components/Loading/Loading";
import ModalGradesSub from "../../components/ModalGradesSub/ModalGradesSub";
import { ToastContainer } from "react-toastify";
import { toastSettings } from "../../models/toastConfig";
import AdminService from "../../services/AdminService";
import HeaderAdmin from "../../components/HeaderAdmin/HeaderAdmin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";

export interface IData {
	grafico: any[];
	informacaoPiorSubRecurso: any;
	notasRecursos: any[];
	pontuacao: string;
	fraseNotaVida: string;
	status: string;
	pessoa: any[any]
}

const PerformanceAdmin = () => {
	const [data, setData] = useState<IData>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { userId } = useParams();

	useEffect(() => {
		if (!data) {
			setIsLoading(true);
			handleList().then();
		}
	}, [data]);

	const handleList = async () => {
		try {
			const [_Response, _Error] = await AdminService.diagnose(
				parseInt(userId || "0"),
			);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				setIsLoading(false);
				return;
			}

			setData(_Response.resultSet);

			setIsLoading(false);
		} catch (err) {
			console.warn(err);
		}
	};

	const render = (data: IData) => {
		return (
			<div className="diagnose">
				<div className="">
					{data?.notasRecursos && data?.notasRecursos.length > 0 ? (
						<ChartPerformance items={data?.grafico} />
					) : (
						<Card className="card-inverse">
							<div className="items-wrapper jcenter">
								<p className="text-center h4 my-5">
									Nenhum dado foi encontrado para esta Jornada.
								</p>
							</div>
						</Card>
					)}
				</div>

				<div className="row">
					<div
						className={`col col-12 resource-notes ${
							data?.notasRecursos && data?.notasRecursos.length > 0 ? "h-100" : ""
						}`}
					>
						<CardGradesResources
							data={
								data?.notasRecursos && data?.notasRecursos.length > 0
									? data.notasRecursos
									: []
							}
							page={"performance"}
							cardscore={
								<div className="col">
									<CardGrades
										pontuacao={data?.pontuacao}
										fraseNotaVida={data?.fraseNotaVida}
										status={data?.status}
									/>
								</div>
							}
							titleTutorial=""
							informacaoPiorSubRecurso={data?.informacaoPiorSubRecurso}
							videoTutorial=""
							showVideo={false}
						/>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div>
			<div className="PerformanceAdmin">
				<div className="container-fluid">
					<HeaderAdmin
						title={data ? `Resultado de ${data?.pessoa?.nomeRazaoSocial || "Ninguém"}` : "Resultado de ..." }
						subtitle="Clique nos recursos para ver o detalhamento das notas"
						icon={<FontAwesomeIcon icon={faSearch} />}
					/>
					{!isLoading ? <>{data && render(data)}</> : <Loading margin="my-6" />}
				</div>
			</div>

			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>

			<ModalGradesSub isAdminDashboard />
		</div>
	);
};

export default PerformanceAdmin;
