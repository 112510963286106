import "./CardScore2.scss";

import { Chart, Doughnut } from "react-chartjs-2";
import React, { FC } from "react";

import FormatNote from "../../utils/FormatNote";
import { useDashboard } from "../../providers/DashboardProvider";

interface CardScore2Props {
	item: any;
}

const CardScore2: FC<CardScore2Props> = ({ item, ...rest }) => {
	const { renderIcon, renderBgIconHex, setShowModalDetails, setIdRecurso } =
		useDashboard();

	Chart.defaults.plugins.tooltip.enabled = false;

	const chartOptions = {
		options: {
			responsive: true,
			cutout: 105,
			legend: {
				display: false,
			},
			animation: {
				animateScale: true,
				animateRotate: true,
			},
		},
	};

	return (
		<div
			className="CardScore2 wrapper"
			data-testid="CardScore2"
			style={{
				borderWidth: 4,
				borderStyle: "solid",
				borderColor: item.piorRecurso
					? renderBgIconHex(item.descricaoRecurso)
					: "transparent",
			}}
			{...rest}
		>
			<div className="card">
				<div className="card-title">
					<div className="image">{renderIcon(item.idRecurso)}</div>
					<div className="grouping">
						<div className="title main">
							<span>{item.descricaoRecurso}</span>
						</div>
					</div>
				</div>

				<div className="sentimento text-center mb-3">
					<img src={item.iconeSentimento} alt="" />
					<p className="mt-1 m-0">{item.descricaoSentimento}</p>
				</div>
			</div>
			<div className="group">
				<div className="text">
					<p className="m-0">{item.informativo}</p>
					<div className="options mt-2">
						<div className="avoid mt-3">
							<span className="titleAvoid">Evite</span>
							<ol className="parentes">
								{item.pontosAtencao.map((item: any, key: number) => {
									if(item.idProcedimentoAcao === 3)
										return <li key={key}>{item.descricao}</li>;
								})}
							</ol>
						</div>
						<div className="does mt-3">
							<span className="titleDoes">Faça</span>
							<ol className="parentes">
								{item.pontosAtencao.map((item: any, key: number) => {
									if(item.idProcedimentoAcao === 4)
										return <li key={key}>{item.descricao}</li>;
								})}
							</ol>
						</div>
					</div>
					<span
						className="link"
						style={{backgroundColor: renderBgIconHex(item.descricaoRecurso) }}
						onClick={() => {
							setShowModalDetails(true);
							setIdRecurso(item.idRecurso);
						}}
					>
						Detalhes da sua nota
					</span>
				</div>
				<div className="graphic">
					<Doughnut
						id="Doughnut"
						data={{
							datasets: [
								{
									data: [parseFloat(item.media), 10 - parseFloat(item.media)],
									backgroundColor: [renderBgIconHex(item.descricaoRecurso), "#8B8A79"],
									hoverBackgroundColor: [
										renderBgIconHex(item.descricaoRecurso),
										"#8B8A79",
									],
									borderWidth: 0,
								},
							],
						}}
						options={chartOptions.options}
						width={250}
						height={250}
					/>
					<p className="m-0">{FormatNote(item.media)}</p>
				</div>
			</div>
		</div>
	);
};

export default CardScore2;
