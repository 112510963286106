import "./PainelMasterPage.scss";

import { Container } from "reactstrap";
import Footer from "../Footer/Footer";
import HeaderPage from "../HeaderPage/HeaderPage";
import Loading from "../Loading/Loading";
import Navbars from "../Navbar/Navbars";
import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { useAuth } from "../../providers/AuthProvider";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	title?: string;
	subtitle?: string;
	titleclass?: string;
	subtitleclass?: string;
	icon?: JSX.Element;
	onClick?: any;
}

const PainelMasterPage: React.FC<Props> = ({
	children,
	onClick,
	title = "",
	subtitle = "",
	titleclass = "",
	subtitleclass = "",
	icon = <></>,
}: Props) => {
	const [sidenavOpen] = React.useState<boolean>(false);
	const { isLoadingScreen } = useAuth();

	return (
		<div className="painel-master-page">
			{/* <Sidebar
				logo={{
					innerLink: "/home",
					imgSrc: "/assets/img/App-Logo-Principal.svg",
					imgAlt: "UELF",
				}}
				rtlActive={false}
			/> */}
			<div className="main-content home" data-testid="Home">
				<Navbars sidenavOpen={sidenavOpen} />
				<HeaderPage
					title={title}
					subtitle={subtitle}
					titleclass={titleclass}
					subtitleclass={subtitleclass}
					icon={icon}
					onClick={onClick}
				/>
				<Container className="pb-6" fluid>
					{children}
				</Container>
				<Footer />
			</div>
			{isLoadingScreen && (
				<div className="is-loading-home">
					<Loading margin="my-6" />
				</div>
			)}
		</div>
	);
};

export default PainelMasterPage;
