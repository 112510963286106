import Cookies from "js-cookie";
import React, { FC, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAdminAuth } from "../providers/AuthAdminProvider";

interface Props {
	element?: any;
	path?: string;
}

export const AuthenticationAdminGate: FC<Props> = () => {
	const [isLoading, setIsLoading] = useState(true);
	const { admin, setAdmin } = useAdminAuth();

	useEffect(() => {
		const disposer = () => setTimeout(() => setIsLoading(false), 0);
		disposer();
	});

	if (isLoading) {
		return <></>;
	}

	if (!admin || !Cookies.get("access_token_admin")) {
		setAdmin(null);
		return <Navigate to="/admin/login" />;
	}

	return (
		<div>
			<Outlet />
		</div>
	);
};
