import { faBell, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import {
	DropdownMenu,
	DropdownToggle,
	ListGroup,
	Nav,
	NavItem,
	UncontrolledDropdown,
	NavLink,
	Navbar,
	Collapse,
} from "reactstrap";
import ThemeToggle from "../ThemeToggle/ThemeToggle";
import "./NavbarAdmin.scss";

interface Props {}

const NavbarAdmin: React.FC<Props> = ({}: Props) => {
	return (
		<div className="NavbarAdmin mt-3">
			<Navbar className="navbar-top navbar-expand">
				<Collapse navbar isOpen={true} className="d-flex justify-content-between">
					<Nav navbar className="menu-area">
						<NavItem>
							<Link to={"/admin/"}>Início</Link>
						</NavItem>
						{/* <NavItem>
							<Link to={"/admin/dashboard"}>Dashboard</Link>
						</NavItem> */}
						<NavItem>
							<Link to={"/admin/detailed-search"}>Pesquisa Detalhada</Link>
						</NavItem>
					</Nav>
					<Nav navbar>
						<NavItem>
							<ThemeToggle />
						</NavItem>
						<UncontrolledDropdown nav>
							<DropdownToggle className="nav-link" color="" tag="a">
								<span>Notificações</span>
								<FontAwesomeIcon icon={faBell} className="notify" />
							</DropdownToggle>
							<DropdownMenu className="dropdown-menu-xl py-0 overflow-hidden" right>
								<div className="px-3 py-3">
									<h6 className="text-sm text-muted text-center m-0">
										<span>Você não possui notificações</span>
									</h6>
								</div>
								<ListGroup flush></ListGroup>
							</DropdownMenu>
						</UncontrolledDropdown>
						<NavItem>
							<NavLink onClick={() => {}}>
								<span>Ajuda</span>
								<FontAwesomeIcon icon={faQuestionCircle} className="help" />
							</NavLink>
						</NavItem>
					</Nav>
				</Collapse>
			</Navbar>
		</div>
	);
};

export default NavbarAdmin;
