import Cookies from "js-cookie";
import Api from "./api";

const AuthAdminService = {
  async login(data: { email: string; password: string }) {
    try {
      const response = await Api.post(`app/admin/auth/login`, {
        email: data.email,
        password: data.password,
      });

			return [response.data, undefined];
		} catch (e: any) {
			return [
				undefined,
				e?.response?.data?.message ||
					"Os dados informados não conferem, por favor verique os dados e tente novamente.",
			];
		}
  },

  logout() {
    localStorage?.clear();
    Cookies.remove("access_token_admin");
    window.location.href = `${process.env.PUBLIC_URL}/login`;

    return true;
  },
};

export default AuthAdminService;
