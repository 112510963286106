import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "./ModalTrail.scss";

interface ModalTrailProps {
	show: boolean;
	source: string;
	onClose?: any;
}

const ModalTrail = ({ show, onClose, source }: ModalTrailProps) => {
	const [modal, setModal] = useState<boolean>(false);

	const toggle = () => setModal(!modal);
	const handleOnClose = () => onClose(false);

	useEffect(() => {
		if (show) {
			setModal(show);
		}
	}, [show]);

	return (
		<Modal
			isOpen={modal}
			toggle={toggle}
			className="ModalTrail"
			data-testid="ModalTrail"
			onClosed={handleOnClose}
			size="xl"
			centered
		>
			<ModalHeader toggle={toggle}></ModalHeader>
			<ModalBody className="d-flex p-0">
				<iframe
					width="560"
					height="315"
					src={source}
					title="Trilha"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				></iframe>
			</ModalBody>
		</Modal>
	);
};

export default ModalTrail;
