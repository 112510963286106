import "./CardResourcesPanelAdmin.scss";

import { useEffect } from "react";
import CardResourceAdmin from "../CardResourceAdmin/CardResourceAdmin";
import { useDashboardAdmin } from "../../providers/DashboardAdminProvider";

const CardResourcesPanelAdmin = ({ subResourcesData }: any) => {
	return (
		<div className="CardResourcesPanelAdmin">
			{subResourcesData?.dadosRecursos &&
				subResourcesData?.dadosRecursos[0].map((recurso: any) => {
					return <CardResourceAdmin key={recurso?.titulo} recurso={recurso} />;
				})}
		</div>
	);
};

// @ts-ignore
export default CardResourcesPanelAdmin;
