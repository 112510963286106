import "./HomeAdmin.scss";

import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import HeaderAdmin from "../../components/HeaderAdmin/HeaderAdmin";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SVG from "react-inlinesvg";
import { useAdminAuth } from "../../providers/AuthAdminProvider";
import GaugeChart from "react-gauge-chart";
import Footer from "../../components/Footer/Footer";
import { useDashboardAdmin } from "../../providers/DashboardAdminProvider";
import { Link } from "react-router-dom";
import ChartPerformanceAdmin from "../../components/ChartPerformanceAdmin/ChartPerformanceAdmin";
import Loading from "../../components/Loading/Loading";
import CardResourcesPanelAdmin from "../../components/CardResourcesPanelAdmin/CardResourcesPanelAdmin";

const HomeAdmin = () => {
	const { admin } = useAdminAuth();
	const { generalData, getData } = useDashboardAdmin();
	const { getSubresourcesData, subResourcesData } = useDashboardAdmin();
	const [isLoading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (!subResourcesData) {
			setLoading(true);
			getSubresourcesData().then(() => setLoading(false));
		}
	}, [subResourcesData]);

	useEffect(() => {
		if (!generalData) {
			getData();
		}
	}, []);

	return (
		<>
			<div className="HomeAdmin">
				<div className="container-fluid">
					<HeaderAdmin
						title="Home"
						subtitle="Área Principal"
						icon={<FontAwesomeIcon icon={faHome} />}
					/>

					<Card className="card-principal">
						<div className="row d-flex flex-row w-100">
							<div>
								<SVG src="./../assets/img/admin/home-1-image.svg" />
							</div>
							<div className="text-content">
								<h1>Olá {admin?.nome ?? ""}</h1>
								<p>Olá, veja como esta a pontuação de seus colaboradores.</p>
							</div>
							<div className="ml-auto mt-auto p-3 d-flex align-items-end flex-column">
								<div>
									<Link to={"./detailed-search"}>
										<Button className="btn btn-secondary">Pesquisa detalhada</Button>
									</Link>
								</div>
							</div>
						</div>
					</Card>

					<Row>
						<Col sm={6} className="p-0">
							{generalData && (
								<Card className="card-secundario">
									<CardBody>
										<CardTitle>
											<h2>Nível de engajamento</h2>
										</CardTitle>
										<Row className="w-100">
											<Col md={8} sm={12}>
												<GaugeChart
													nrOfLevels={3}
													colors={["#FF5F6D", "#fff361", "#64ff61"]} 
													arcWidth={0.2}
													percent={generalData?.dadosValuation?.percentualConcluido / 100}
													hideText
												/>
											</Col>
											<Col className="d-flex align-items-center text-center">
												<h2 className="text-info">
													{generalData?.dadosValuation?.totalConcluido} /{" "}
													{generalData?.dadosValuation?.totalPessoas} <br />
													Responderam
												</h2>
											</Col>
										</Row>
										<Row>
											<span className="text-center w-100">
												{generalData?.dadosValuation?.percentualConcluido}% dos seus
												colaboradores cadastrados responderam o UELF Valuation
											</span>
										</Row>
									</CardBody>
								</Card>
							)}
						</Col>
					</Row>

					<div className="dashboard-geral">
						<Card className="card-principal">
							<div className="row d-flex flex-row w-100">
								<CardBody>
									<CardTitle>
										<h2>A média das notas de cada recurso</h2>
									</CardTitle>
									{isLoading ? (
										<Loading />
									) : (
										<ChartPerformanceAdmin subResourcesData={subResourcesData} />
									)}
								</CardBody>
							</div>
						</Card>

						{isLoading ? (
							<Loading />
						) : (
							<CardResourcesPanelAdmin subResourcesData={subResourcesData} />
						)}
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};

// @ts-ignore
export default HomeAdmin;
