import "./PurposeStep.scss";

import { Badge, Button } from "reactstrap";
import React, { useState } from "react";

import CheckBox from "../CheckBox/CheckBox";
import FileInput from "../FileInput/FileInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputText from "../InputText/InputText";
import { Link } from "react-router-dom";
import PurposeService from "../../services/PurposeService";
import RadioButton from "../RadioButton/RadioButton";
import RadioButtonVar from "../RadioButtonVar/RadioButtonVar";
import RangeSlider from "../RangeSlider/RangeSlider";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { toastSettings } from "../../models/toastConfig";
import { useStages } from "../../providers/StagesProvider";
import GoogleTagManager, { EventEnum } from "../../services/GoogleTagManager";
import Slugify from "../../utils/Slugify";

// import FileInput from "../FileInput/FileInput";

export interface IStepProps {
	steps: Array<StepsContent>;
}

export interface StepsContent {
	idObjetivo: number;
	idJornadaObjetivo: number;
	descObjetivo: string;
	titulo: string;
	subTitulo: string;
	imagem: string;
	tituloObjetivo: string;
	mensagem: string;
	botao: string;
	status: number;
	questao: Array<QuestionsContent>;
	exibiretapa: boolean;
}

export interface QuestionsContent {
	idQuestao: number;
	descQuestao: string;
	tipoquestao: string;
	alternativas: Array<AlternativesContent>;
}

export interface AlternativesContent {
	id: number;
	descricao: string;
	valor?: number;
	tipoAlternativa: string;
	respostas: any;
}

export interface AnswersContent {
	id: number;
	resposta: string;
	idObjetivoQuestaoAlternativa: number;
	idJornadaObjetivo: number;
}

const PurposeStep = ({ steps }: IStepProps): JSX.Element => {
	const [currentStep, setCurrentStep] = useState<number>(0);
	const { currentStage, handleNextStage } = useStages();
	const [selectedImagem, setSelectedImagem] = useState<File>();

	const { setCurrentStageName } = useStages();

	const handleNextStep = () => {
		setCurrentStep(currentStep + 1);
	};

	const handlePost = async (data: any, imagem: File | undefined) => {
		try {
			const [_Response, _Error] = await new PurposeService().post(data, imagem);

			if (!!_Error || !_Response?.status) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
				return false;
			}

			return true;
		} catch (err) {
			return false;
		}
	};

	const completeStep = (id: number, journeyId: number, step?: number) => {
		interface Idata {
			idJornadaObjetivo: number;
			respostas: Array<{
				idQuestao: string | any;
				idObjetivoQuestaoAlternativa: number;
				resposta: any;
			}>;
			anexo?: File;
		}

		let data: Idata = {
			idJornadaObjetivo: 0,
			respostas: [],
		};

		let ret = true;

		if (id === 1) {
			data.idJornadaObjetivo = journeyId;

			let ranges = document.querySelectorAll("input[type='range']");

			ranges.forEach((range, index) => {
				let input = range as HTMLInputElement;

				data.respostas.push({
					idQuestao: input.dataset.questionId,
					idObjetivoQuestaoAlternativa: index + 1,
					resposta: Number(input.value),
				});
			});
		} else if (id === 2) {
			data.idJornadaObjetivo = journeyId;

			let cboxes = document.querySelectorAll("input[type='checkbox']:checked");

			cboxes.forEach((cbox) => {
				let input = cbox as HTMLInputElement;

				let value = input.id.split("-");

				data.respostas.push({
					idQuestao: input.dataset.questionId,
					idObjetivoQuestaoAlternativa: Number(value[value.length - 1]),
					resposta: input.value,
				});
			});

			let radios = document.querySelectorAll("input[type='radio']:checked");

			radios.forEach((radio) => {
				let input = radio as HTMLInputElement;

				let value = input.id.split("-");

				data.respostas.push({
					idQuestao: input.dataset.questionId,
					idObjetivoQuestaoAlternativa: Number(value[value.length - 1]),
					resposta: input.value,
				});
			});
		} else if (id === 3) {
			data.idJornadaObjetivo = journeyId;

			let cboxes = document.querySelectorAll("input[type='checkbox']:checked");

			cboxes.forEach((cbox) => {
				let input = cbox as HTMLInputElement;

				let value = input.id.split("-");

				data.respostas.push({
					idQuestao: input.dataset.questionId,
					idObjetivoQuestaoAlternativa: Number(value[value.length - 1]),
					resposta: input.value,
				});
			});

			let radios = document.querySelectorAll("input[type='radio']:checked");

			radios.forEach((radio) => {
				let input = radio as HTMLInputElement;

				let value = input.id.split("-");

				data.respostas.push({
					idQuestao: input.dataset.questionId,
					idObjetivoQuestaoAlternativa: Number(value[value.length - 1]),
					resposta: input.value,
				});
			});
		} else if (id === 4) {
			data.idJornadaObjetivo = journeyId;

			let textInputs = document.querySelectorAll("input[type='text']");

			textInputs.forEach((txtInput) => {
				let input = txtInput as HTMLInputElement;

				let value = input.id.split("-");

				let reg_only_text_without_numbers = /[a-zA-Z\u00C0-\u00FF .,!?]/g;

				if (input.value.match(reg_only_text_without_numbers) == null) {
					ret = false;
					toastSettings(
						"O campo deve conter apenas letras",
						"bottom-center",
						"error",
					);
					return;
				}

				if (input.value.length > 150) {
					ret = false;
					toastSettings(
						"A resposta deve conter no maximo 150 caracteres!!",
						"bottom-center",
						"error",
					);
					return;
				}

				data.respostas.push({
					idQuestao: input.dataset.questionId,
					idObjetivoQuestaoAlternativa: Number(value[value.length - 1]),
					resposta: input.value,
				});
			});

			let filesInput = document.querySelectorAll("input[type='file']");

			filesInput.forEach((fileInput) => {
				let input = fileInput as HTMLInputElement;

				// if (!input.files?.length) {
				// 	ret = false;
				// 	toastSettings("A imagem é obrigatoria!.", "bottom-center", "error");
				// 	return;
				// }

				if (input?.files && input?.files[0]?.size > 1000000) {
					ret = false;
					toastSettings("Imagem maior que 1Mb.", "bottom-center", "error");
					return;
				}

				let value = input.id.split("-");

				data.respostas.push({
					idQuestao: input.dataset.questionId,
					idObjetivoQuestaoAlternativa: Number(value[value.length - 1]),
					resposta: "imagem",
				});
			});
		}

		if (ret) {
			handlePost(data, selectedImagem).then((ret: boolean) => {
				if (ret) {
					handleNextStage(steps.length);

					if (step) {
						setCurrentStep(step);
					} else {
						setCurrentStep(0);
					}
				}
			});
		}
	};

	const onImageChange = (event: any) => {
		if (event.target.files && event.target.files[0]) {
			let img = event.target.files[0];
			setSelectedImagem(img);
		}
	};

	const renderStep = () => {
		for (let i = 0; i < steps.length; i++) {
			if (i === currentStage) {
				if (currentStep === 0) {
					if (steps[i].exibiretapa === true) {
						let bg = "url('assets/img/selfknowledge-0" + i + ".png')";

						// if (steps[i].imagem) {
						// 	// bg = `url(${process.env.REACT_APP_UELF_API}/${steps[i].imagem})`;
						// 	bg = `url(${steps[i].imagem})`;
						// } else {
						// bg = "url('assets/img/selfknowledge-0" + i + ".png')";
						// }

						return (
							<div className={`content-container${" key-" + currentStage}`}>
								<div className="step initial">
									<div className="text-content">
										<h2 className="title">{steps[i].titulo}</h2>
										<div className="text">
											<span>{steps[i].subTitulo}</span>
											<span>{steps[i].mensagem}</span>
										</div>
										<br />
										<Button
											color="dark"
											className="step-forward"
											onClick={() => handleNextStep()}
										>
											{steps[i].botao}
										</Button>
									</div>
									<div
										className="image-bg"
										style={{
											backgroundImage: bg,
										}}
									></div>
								</div>
							</div>
						);
					} else {
						setCurrentStep(1);
					}
				} else if (currentStep === 1) {
					return (
						<div className={`content-container${" key-" + currentStage}`}>
							<div className="step secondary">
								<div className="title-wrapper">
									<h2 className="title">{steps[i].tituloObjetivo}</h2>
									<Badge className={"key-" + currentStage} color="dark" pill>
										Etapa: {currentStage + 1} / 4
									</Badge>
								</div>
								{steps[i].questao.map((question: QuestionsContent, index: number) => {
									let type = question.tipoquestao;

									if (type === "range") {
										let range = question.alternativas[0].descricao.split("-");
										let value = 0;

										if (question.alternativas[0].respostas.at(-1)) {
											value = question.alternativas[0].respostas.at(-1).resposta
												? question.alternativas[0].respostas.at(-1).resposta
												: 0;
										}

										return (
											<RangeSlider
												key={index}
												id={"range-" + index}
												min={Number(range[0])}
												max={Number(range[1])}
												value={Number(value)}
												qNum={"0" + (index + 1) + " - "}
												dataQuestionId={question.idQuestao}
												label={question.descQuestao}
											/>
										);
									} else if (type === "checkbox") {
										return (
											<div className="checkbox-group" key={index}>
												<label className="checkbox-label" htmlFor={"checkbox-" + index}>
													<b>{"0" + (index + 1) + " - "}</b>
													{question.descQuestao}
												</label>
												<div className="checkbox-row">
													{question.alternativas.map((option, OpIndex) => {
														let state = option.respostas.at(-1) ? true : false;
														return (
															<CheckBox
																key={OpIndex}
																id={"checkbox-" + index + "-" + option.id}
																name={"checkbox-group-" + index}
																state={state}
																value={option.descricao}
																dataQuestionId={question.idQuestao}
																label={option.descricao}
															/>
														);
													})}
												</div>
											</div>
										);
									} else if (type === "radio") {
										return (
											<div className="radio-group" key={index}>
												<label className="radio-label" htmlFor={"radio-" + index}>
													<b>{"0" + (index + 1) + " - "}</b>
													{question.descQuestao}
												</label>
												<div className="radio-row">
													{question.alternativas.map((option, OpIndex) => {
														let state = option.respostas.at(-1) ? true : false;
														return (
															<RadioButton
																key={OpIndex}
																id={"radio-" + index + "-" + option.id}
																name={"radio-group-" + index}
																state={state}
																value={option.descricao}
																dataQuestionId={question.idQuestao}
																label={option.descricao}
															/>
														);
													})}
												</div>
											</div>
										);
									} else if (type === "radio-btn") {
										return (
											<div className="radio-button-var-group" key={index}>
												<label
													className="radio-button-var-label"
													htmlFor={"radio-button-var-" + index}
												>
													<b>{"0" + (index + 1) + " - "}</b>
													{question.descQuestao}
												</label>
												<div className="radio-button-var-row">
													{question.alternativas.map((option, OpIndex) => {
														let state = option.respostas.at(-1) ? true : false;
														return (
															<RadioButtonVar
																key={OpIndex}
																id={"radio-button-var-" + index + "-" + option.id}
																name={"radio-button-var-group-" + index}
																state={state}
																value={option.descricao}
																dataQuestionId={question.idQuestao}
																label={option.descricao}
															/>
														);
													})}
												</div>
											</div>
										);
									} else if (type === "text") {
										let answer = question.alternativas[0].respostas.at(-1)
											? question.alternativas[0].respostas.at(-1).resposta
											: "";
										return (
											<InputText
												key={index}
												id={"input-text-" + question.alternativas[0].id}
												num={"0" + (index + 1) + " - "}
												label={question.descQuestao}
												dataQuestionId={question.idQuestao}
												answer={answer}
											/>
										);
									} else if (type === "file") {
										return (
											<>
												<FileInput
													key={index}
													id={"input-file-" + question.alternativas[0].id}
													num={"0" + (index + 1) + " - "}
													label={question.descQuestao}
													dataQuestionId={question.idQuestao}
													onImageChange={onImageChange}
												/>
											</>
										);
									} else if (type == "think") {
										return (
											<div className="text" key={index}>
												<label className="input-text-label">
													<b>{"0" + (index + 1) + " - "}</b>
													{question.descQuestao}
												</label>
											</div>
										);
									} else {
										return (
											<div className="text" key={index}>
												<p>
													<i className="bi bi-bug-fill"></i>
													<span>Não foi possível carregar esse segmento.</span>
												</p>
											</div>
										);
									}
								})}
								{i === steps.length - 1 ? (
									<Button
										color="dark"
										className="step-forward"
										onClick={() =>
											completeStep(
												Number(steps[i].idObjetivo),
												Number(steps[i].idJornadaObjetivo),
												2,
											)
										}
									>
										Salvar
									</Button>
								) : (
									<Button
										color="dark"
										className="step-forward"
										onClick={() =>
											completeStep(
												Number(steps[i].idObjetivo),
												Number(steps[i].idJornadaObjetivo),
											)
										}
									>
										Salvar
									</Button>
								)}
							</div>
						</div>
					);
				} else if (currentStep === 2) {
					setCurrentStageName("Etapas Concluídas");
					GoogleTagManager.event(EventEnum.Marketing, { action: Slugify("4etapas_conclusao") });	
					let bg = "url('assets/img/selfknowledge-04.png')";
					return (
						<div className={`content-container${" key-" + currentStage}`}>
							<div className="step final text-white">
								<div className="text-content">
									<div className="icon">
										<FontAwesomeIcon icon={faCheckCircle} />
									</div>
									<h2 className="title p text-white">
										Parabéns, você concluiu a sua avaliação de autoconhecimento!
									</h2>
									<div className="text text-white">
										<p>
										Preencha agora o questionário de avaliação pessoal! Este é um excelente exercício para que você identifique as melhorias necessárias em sua vida!
										</p>
										<p>Esta atividade possui duração média de 10 minutos.</p>
									</div>

									<Link to={"/survey"}>
										<Button color="dark" className="step-forward">
											Continuar
										</Button>
									</Link>
								</div>
								<div
									className="image-bg"
									style={{
										backgroundImage: bg,
									}}
								></div>
							</div>
						</div>
					);
				} else {
					return (
						<div className="content-container">
							<div className="step error">
								<h2 className="title">Algo deu errado.</h2>
								<div className="text">
									<p>Não foi possível carregar este passo da etapa atual.</p>
								</div>
							</div>
						</div>
					);
				}
			}
		}
	};

	return <>{renderStep()}</>;
};

export default PurposeStep;
