import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
	Button,
	Col,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
} from "reactstrap";
import { toastSettings } from "../../models/toastConfig";
import AdminService from "../../services/AdminService";
import CheckBox from "../CheckBox/CheckBox";
import "./ModalFilterAdmin.scss";

interface ModalFilterAdminProps {
	show: boolean;
	onClose?: any;
	setSelectFilterSetor?: any;
	setSelectFilterDtConclusao?: any;
}

const ModalFilterAdmin = ({
	show,
	onClose,
	setSelectFilterSetor,
	setSelectFilterDtConclusao,
}: ModalFilterAdminProps) => {
	const [modal, setModal] = useState<boolean>(false);

	const [filterSetor, setFilterSetor] = useState<boolean>(false);
	const [filterDtConclusao, setFilterDtConclusao] = useState<boolean>(false);

	const [selectedFilterSetor, setSelectedFilterSetor] = useState<string>("");
	const [selectedFilterDtConclusao, setSelectedFilterDtConclusao] = useState<string>("");

	const [occupations, setOccupations] = useState([]);

	useEffect(() => {
		if (show) {
			setModal(show);
		}
	}, [show]);

	useEffect(() => {
		if (!occupations.length) {
			getUsers();
		}
	}, []);

	const toggle = () => setModal(!modal);
	const handleOnClose = () => onClose(false);

	useEffect(() => {
		setSelectFilterSetor(filterSetor ? selectedFilterSetor : "");
		setSelectFilterDtConclusao(filterDtConclusao ? selectedFilterDtConclusao : "");
	}, [selectedFilterSetor, selectedFilterDtConclusao, filterSetor, filterDtConclusao]);

	const getUsers = () => {
		AdminService.listAllOccupation().then((data) => {
			const [_Response, _Error] = data;

			if (_Response?.status && !_Error) {
				setOccupations(_Response?.resultSet);
			} else if (_Error) {
				toastSettings(_Error || _Response?.message, "bottom-center", "error");
			}
		});
	};

	return (
		<Modal
			centered
			scrollable
			size="lg"
			isOpen={modal}
			toggle={toggle}
			onClosed={handleOnClose}
			className="ModalFilterAdmin"
		>
			<ModalHeader>
				<h1>Filtros</h1>
			</ModalHeader>
			<ModalBody>
				<Row>
					<Col>
						<div className="form-check">
							<input
								className="form-check-input"
								type="checkbox"
								value="setor"
								id="filterSetor"
								checked={filterSetor}
								onChange={(e) => setFilterSetor(e.target.checked)}
							/>
							<label className="form-check-label">Setor</label>
							{filterSetor && (
								<div className="w-50">
									<select
										id="selectFilterSetor"
										className="form-control"
										onChange={(e) => setSelectedFilterSetor(e.target.value)}
										value={selectedFilterSetor}
									>
										<option value="" selected>Todos</option>
										{
											occupations?.map((o: any) => {
												return <option value={o?.ocupacao} selected>{o?.ocupacao}</option>
											})
										}
									</select>
								</div>
							)}
						</div>
						<div className="form-check">
							<input
								className="form-check-input"
								type="checkbox"
								value="dtConclusao"
								id="filterDtConclusao"
								checked={filterDtConclusao}
								onChange={(e) => setFilterDtConclusao(e.target.checked)}
							/>
							<label className="form-check-label">Data Conclusão</label>
							{filterDtConclusao && (
								<div className="w-50">
									<input
										className="form-control"
										type="date"
										value={selectedFilterDtConclusao}
										onChange={(e) => setSelectedFilterDtConclusao(e.target.value)}
									/>
								</div>
							)}
						</div>
					</Col>
				</Row>
			</ModalBody>
			<ModalFooter>
				<Button type="button" onClick={toggle}>
					<FontAwesomeIcon icon={faXmark} />
					<span>Fechar</span>
				</Button>
			</ModalFooter>
		</Modal>
	);
};

export default ModalFilterAdmin;
